import { api } from '@fleet/shared';
import { AxiosError } from 'axios';
import { PaymentMethod } from 'components/PaymentMethods';
import {
  BookedOffer,
  BookingDetailsPassenger,
  BookingTripWithAdmissions,
  BookingValidation,
  FulfillmentStatus,
} from 'dto/booking';
import { ErrorResponse } from 'dto/error';
import { TripLeg } from 'dto/trip';
import {
  sendConfirmation,
  sendTicketDelivery,
  setCurrentBooking,
  triggerBookingFulfillment,
} from 'features/booking/bookingActions';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import {
  payByLink,
  PurchaserDetailsPayload,
  updatePurchaserDetails,
} from 'features/trip/tripActions';
import { FormApi } from 'final-form';
import _groupBy from 'lodash/groupBy';
import partition from 'lodash/partition';
import { useCallback, useMemo } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { TicketSelectionPayload } from 'routes/tickets/checkout/Overview';
import { useDispatch, useSelector } from 'store/utils';
import { FEATURE_TICKET_DELIVERY_V2, IS_IMS_AT } from 'utils/flags';
import { getTripAdmissions, isAdmissionInactive } from 'utils/trip';

export const prefillPayerFormData = (
  passengerId: string,
  passengers: BookingDetailsPassenger[],
  form: FormApi<PurchaserDetailsPayload, Partial<PurchaserDetailsPayload>>
) => {
  const passenger = passengers.find(({ id }) => id === passengerId);
  if (passenger) {
    const { firstName, lastName, contactInformation } = passenger;
    form.batch(() => {
      form.change('firstName', firstName.value);
      form.change('lastName', lastName.value);
      form.change('email', contactInformation.emailAddress.value);
      // @ts-ignore
      form.change('phone.number', contactInformation.phoneNumber.value);
    });
  } else {
    form.reset({});
  }
};

export const usePurchaserInfo = (
  payerDetailsForm: FormApi<
    PurchaserDetailsPayload,
    Partial<PurchaserDetailsPayload>
  >,
  values: PurchaserDetailsPayload
) => {
  return useMemo(() => {
    let purchaserPhoneNumber: string | undefined;
    let purchaserEmail: string | undefined;
    const phoneNumberState = payerDetailsForm.getFieldState(
      'phone.number' as keyof PurchaserDetailsPayload
    );

    if (payerDetailsForm.getFieldState('email')?.valid) {
      purchaserEmail = values.email;
    }

    if (phoneNumberState?.valid) {
      purchaserPhoneNumber = values.phone?.number;
    }

    return { purchaserEmail, purchaserPhoneNumber };
  }, [payerDetailsForm, values]);
};

export const prepareTrips = (
  trips?: Array<BookingTripWithAdmissions>
): Array<BookingTripWithAdmissions> => {
  if (!trips) return [];
  const sortedTrips = [...trips].sort(
    (a, b) =>
      new Date(a.departureTime).getTime() - new Date(b.departureTime).getTime()
  );
  const tripsByJourneyRef = _groupBy(sortedTrips, 'journeyRef');
  const journeys = Object.keys(tripsByJourneyRef);
  return journeys
    .map((ref) => {
      const journeyTrips = tripsByJourneyRef[ref].filter(
        ({ bookedOffers }) =>
          !bookedOffers.every(({ admissions }) =>
            admissions.every(isAdmissionInactive)
          )
      );
      const [outboundTrips, inboundTrips] = partition(
        journeyTrips,
        ({ isOutbound }) => isOutbound
      );

      return [outboundTrips, inboundTrips]
        .filter((arr) => arr.length)
        .map((trips) => {
          const [firstJourneyTrip, ...restJourneyTrips] = trips;
          const legs = trips.reduce<Array<TripLeg>>(
            (legs, trip) => [...legs, ...trip.legs],
            []
          );

          return {
            ...firstJourneyTrip,
            bookedOffers: [
              ...firstJourneyTrip.bookedOffers,
              ...restJourneyTrips.reduce<Array<BookedOffer>>(
                (acc, { bookedOffers }) => [...acc, ...bookedOffers],
                []
              ),
            ],
            legs,
            arrivalTime: trips[trips.length - 1].arrivalTime,
            destinationStop: trips[trips.length - 1].destinationStop,
          };
        });
    })
    .flat();
};

export const useConfirmationSendv1 = (bookingId?: string) => {
  const dispatch = useDispatch();
  return useCallback(
    async ({
      emailConfirmationRecipient = [],
      additionalEmailConfirmationRecipients = [],
      smsConfirmationRecipient = [],
      additionalSmsConfirmationRecipients = [],
      passengerSelection = [],
      includeTickets,
    }: TicketSelectionPayload) => {
      if (!bookingId) return;
      const hasRecipients =
        passengerSelection?.length > 0 ||
        emailConfirmationRecipient?.length > 0 ||
        additionalEmailConfirmationRecipients?.length > 0 ||
        smsConfirmationRecipient?.length > 0 ||
        additionalSmsConfirmationRecipients?.length > 0;
      if (!hasRecipients) return;
      const emailConfirmationRecipients = [
        ...emailConfirmationRecipient,
        ...additionalEmailConfirmationRecipients,
      ];
      const smsConfirmationRecipients = [
        ...smsConfirmationRecipient,
        ...additionalSmsConfirmationRecipients,
      ];
      await Promise.all([
        ...(emailConfirmationRecipients.length ||
        smsConfirmationRecipients.length
          ? [
              dispatch(
                sendConfirmation({
                  url: 'purchase-confirmation',
                  bookingId,
                  includeTickets,
                  emailsOverride: emailConfirmationRecipients,
                  phoneNumbersOverride: smsConfirmationRecipients.map(
                    (phoneNo) => ({
                      number: phoneNo,
                    })
                  ),
                })
              ),
            ]
          : []),
        ...(passengerSelection.length
          ? [
              dispatch(
                sendConfirmation({
                  url: 'ticket-delivery',
                  bookingId,
                  passengers: passengerSelection.map((passengerId: string) => ({
                    passengerId,
                  })),
                })
              ),
            ]
          : []),
      ]);
    },
    [bookingId, dispatch]
  );
};

export const useConfirmationSendv2 = (
  bookingId?: string,
  skipConfirmationEndpoint?: boolean
) => {
  const dispatch = useDispatch();
  return useCallback(
    async ({
      emailConfirmationRecipient = [],
      additionalEmailConfirmationRecipients = [],
      smsConfirmationRecipient = [],
      additionalSmsConfirmationRecipients = [],
      passengers = [],
    }: TicketSelectionPayload) => {
      if (!bookingId) return;

      const ticketDeliverySelected = passengers.some(
        ({ sendEmail, sendSms }) => sendSms || sendEmail
      );
      const hasRecipients =
        ticketDeliverySelected ||
        emailConfirmationRecipient?.length > 0 ||
        additionalEmailConfirmationRecipients?.length > 0 ||
        smsConfirmationRecipient?.length > 0 ||
        additionalSmsConfirmationRecipients?.length > 0;
      if (!hasRecipients) return;
      const emailConfirmationRecipients = [
        ...emailConfirmationRecipient,
        ...additionalEmailConfirmationRecipients,
      ];
      const smsConfirmationRecipients = [
        ...smsConfirmationRecipient,
        ...additionalSmsConfirmationRecipients,
      ];
      const shouldSendPurchaseConfirmation =
        emailConfirmationRecipients.length ||
        (smsConfirmationRecipients.length && !skipConfirmationEndpoint);
      if (ticketDeliverySelected || shouldSendPurchaseConfirmation) {
        await dispatch(
          sendTicketDelivery({
            bookingId,
            includeTaxInvoices: true,
            passengers: passengers.map(
              ({
                currentEmailOverride,
                currentPhoneOverride,
                phoneNumberOverride,
                emailOverride,
                currentEmail,
                currentPhone,
                sendSms,
                sendEmail,
                ...rest
              }) => ({
                ...rest,
                sendEmail,
                sendSms,
                phoneNumberOverride: sendSms
                  ? [
                      currentPhoneOverride || currentPhone,
                      ...(phoneNumberOverride ?? []),
                    ].filter(Boolean)
                  : [],
                emailOverride: sendEmail
                  ? [
                      currentEmailOverride || currentEmail,
                      ...(emailOverride ?? []),
                    ].filter(Boolean)
                  : [],
              })
            ),
            ...(shouldSendPurchaseConfirmation && {
              purchaser: {
                emailOverride: emailConfirmationRecipients,
                phoneNumberOverride: smsConfirmationRecipients,
                sendSms: !!smsConfirmationRecipients?.length,
                sendEmail: !!emailConfirmationRecipients?.length,
              },
            }),
          })
        ).unwrap();
      }
    },
    [bookingId, dispatch, skipConfirmationEndpoint]
  );
};

export const useConfirmationSend = () => {
  const currentBooking = useSelector(currentBookingSelector);
  const handleConfirmationSendv1 = useConfirmationSendv1(currentBooking?.id);
  const handleConfirmationSendv2 = useConfirmationSendv2(
    currentBooking?.id,
    currentBooking?.bookedTrips
      .flatMap((trip) => getTripAdmissions(trip))
      .some(({ status }) => status === FulfillmentStatus.ON_HOLD)
  );

  if (FEATURE_TICKET_DELIVERY_V2) return handleConfirmationSendv2;
  return handleConfirmationSendv1;
};

export const useSubmitBooking = ({
  bookingId,
  ticketFulfillmentForm,
  paymentMethod,
  showSuccessPage,
  isModifySubmit,
}: {
  bookingId: string;
  ticketFulfillmentForm: FormApi<TicketSelectionPayload>;
  paymentMethod: string;
  showSuccessPage: () => void;
  isModifySubmit?: boolean;
}) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  return useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async ({ prefillPassengerId: _, ...values }: PurchaserDetailsPayload) => {
      if (ticketFulfillmentForm.getState().invalid)
        return ticketFulfillmentForm.submit();
      if (IS_IMS_AT) {
        const validations = (
          await api.post<{ notFulfilledValidations: Array<BookingValidation> }>(
            `/bookings/${bookingId}/validate`,
            { scope: ['UMNR'] }
          )
        ).data.notFulfilledValidations;
        if (validations?.length) {
          return validations.forEach(({ detail }) => alert.error(detail));
        }
      }

      await dispatch(
        updatePurchaserDetails({
          bookingId,
          ...values,
        })
      ).unwrap();
      let updatedBooking;

      if (paymentMethod === PaymentMethod.adyen) {
        try {
          return await dispatch(
            payByLink({
              bookingId,
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              phoneNumber: values.phone?.number,
            })
          );
        } catch (e) {
          if (
            (e as AxiosError<ErrorResponse>)?.response?.data?.errors?.find(
              ({ message }) => message === 'The booking is already confirmed'
            )
          ) {
            showSuccessPage();
          }
        }
      } else {
        updatedBooking = await dispatch(
          triggerBookingFulfillment({
            bookingId,
            shouldWaitForExchangeGetBooking: isModifySubmit,
          })
        ).unwrap();
        showSuccessPage();
      }

      await ticketFulfillmentForm.submit();

      updatedBooking && dispatch(setCurrentBooking(updatedBooking));
    },
    [
      ticketFulfillmentForm,
      dispatch,
      bookingId,
      paymentMethod,
      alert,
      showSuccessPage,
      isModifySubmit,
    ]
  );
};

export const useValidatePhoneNumber = () => {
  const { t } = useTranslation();

  return useCallback(
    (value: string) => {
      if (value && !/^\+\d+$/.test(value)) {
        return t('field.invalidPhoneNumber', 'Invalid phone number format');
      }
      return null;
    },
    [t]
  );
};
