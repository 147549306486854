import {
  Button,
  FormProvider,
  Icon,
  Layout,
  Loadable,
  Loader,
  Modal,
  TabPanel,
  Tabs,
} from '@fleet/shared';
import { useForm } from '@fleet/shared/form';
import { useModal } from '@fleet/shared/hooks';
import { CardHeader } from '@fleet/shared/mui';
import { noop } from '@fleet/shared/utils/noop';
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { EmailFulfillment } from 'components/ticketFulfillment/EmailFulfillment';
import { SmsFulfillment } from 'components/ticketFulfillment/SmsFulfillment';
import {
  cancelExchangeOperations,
  getBooking,
  getComments,
  getHistory,
  getNotifications,
  getTravelAccount,
  getUnaccompaniedMinorRequests,
  resetCurrentBooking,
} from 'features/booking/bookingActions';
import {
  bookingAdmissionsSelector,
  bookingCommentsSelector,
  bookingNotificationsPresentSelector,
  bookingUnaccompaniedMinorRequestsSelector,
  currentBookingSelector,
} from 'features/booking/bookingSelectors';
import { currentBookingLoadingSelector } from 'features/loading/loadingSelectors';
import { selectEmailSendAvailable } from 'features/user/userSelector';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTitle } from 'i18n/trans/title';
import _isEmpty from 'lodash/isEmpty';
import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BookingsDetailsCard } from 'routes/bookingDetails/BookingsDetailsCard';
import { Fees } from 'routes/bookingDetails/Fees';
import { Comments } from 'routes/bookingDetails/tabs/Comments';
import { CustomerCommunication } from 'routes/bookingDetails/tabs/CustomerCommunication';
import { History } from 'routes/bookingDetails/tabs/History';
import { PassengersAndTickets } from 'routes/bookingDetails/tabs/PassengersAndTickets';
import { PayerData } from 'routes/bookingDetails/tabs/PayerData';
import { Route } from 'routes/bookingDetails/tabs/Route';
import { TicketSelectionPayload } from 'routes/tickets/checkout/Overview';
import { useDispatch, useSelector } from 'store/utils';
import { FEATURE_TICKET_DELIVERY_V2, IS_IMS_AT } from 'utils/flags';
import { useConfirmationSend } from 'utils/overview';
import { downloadBookingTickets } from 'utils/trip';
import _isEqual from 'lodash/isEqual';
import { FulfillmentStatus } from 'dto/booking';

interface BookingsDetailsProps {}

const tabKeys = [
  'passengersAndTickets',
  'fees',
  'route',
  'payerData',
  'eventsAndPayments',
  ...(IS_IMS_AT ? (['customerCommunication'] as const) : []),
  'comments',
] as const;

const SEND_TICKETS_FORM_ID = 'sendTicketsForm';

const useStyles = makeStyles(
  (theme) => ({
    sendTicketsModal: {
      '& .MuiPaper-root': {
        width: '50rem',
      },
      '& .MuiDialogTitle-root > .MuiTypography-root': {
        fontSize: theme.typography.h2.fontSize,
      },
      '& .MuiDialogContent-root': {
        padding: 0,
        paddingBottom: '1rem',
      },
    },
    tabIcon: {
      transform: 'translateY(-0.375rem)',
      marginLeft: '0.25rem',
    },
  }),
  { name: 'BookingDetails' }
);

export const BookingDetails: FC<BookingsDetailsProps> = () => {
  const classes = useStyles();
  const { open, onOpen, onClose } = useModal();
  const currentBooking = useSelector(currentBookingSelector);
  const [activeTab, setActiveTab] = useState<typeof tabKeys[number]>(
    tabKeys[0]
  );
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(currentBookingLoadingSelector);
  const emailSendAvailable = useSelector(selectEmailSendAvailable);
  const hasNotifications = useSelector(bookingNotificationsPresentSelector);
  const comments = useSelector(bookingCommentsSelector);
  const unaccompaniedMinorRequests = useSelector(
    bookingUnaccompaniedMinorRequestsSelector
  );
  const bookingAdmissions = useSelector(bookingAdmissionsSelector, _isEqual);
  const areAllAdmissionsOnHold = useMemo(
    () =>
      bookingAdmissions.every((adm) =>
        adm.fulfillments.every(
          (f) => f.status === ('ONHOLD' as FulfillmentStatus)
        )
      ),
    [bookingAdmissions]
  );
  const handleConfirmationSend = useConfirmationSend();
  const onSubmit = useCallback(
    async (payload: TicketSelectionPayload) => {
      await handleConfirmationSend(payload);
      onClose();
    },
    [handleConfirmationSend, onClose]
  );
  const ticketFulfillmentInitialValues = useMemo<
    Partial<TicketSelectionPayload>
  >(
    () =>
      FEATURE_TICKET_DELIVERY_V2
        ? {
            passengers:
              currentBooking?.passengers.map(({ id, contactInformation }) => ({
                passengerId: id,
                currentEmail: contactInformation.emailAddress.value,
                currentPhone: contactInformation.phoneNumber.value,
                sendSms: false,
                sendEmail: false,
              })) ?? [],
          }
        : {},
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open]
  );
  const {
    form: ticketFulfillmentForm,
    handleSubmit,
    values,
  } = useForm<TicketSelectionPayload>({
    initialValues: ticketFulfillmentInitialValues,
    onSubmit,
    subscription: { values: true },
  });

  const onTicketDownload = useCallback(async () => {
    downloadBookingTickets(currentBooking);
  }, [currentBooking]);

  const sendTicketsDisabled = useMemo(() => {
    const {
      emailConfirmationRecipient = [],
      additionalEmailConfirmationRecipients = [],
      smsConfirmationRecipient = [],
      additionalSmsConfirmationRecipients = [],
      // v1
      passengerSelection = [],
      includeTickets,
      // v2
      passengers = [],
    }: TicketSelectionPayload = values;
    const emailConfirmationRecipients = [
      ...emailConfirmationRecipient,
      ...additionalEmailConfirmationRecipients,
    ];
    const smsConfirmationRecipients = [
      ...smsConfirmationRecipient,
      ...additionalSmsConfirmationRecipients,
    ];
    return FEATURE_TICKET_DELIVERY_V2
      ? passengers.every(({ sendSms, sendEmail }) => !sendEmail && !sendSms) &&
          [emailConfirmationRecipients, smsConfirmationRecipients].every(
            _isEmpty
          )
      : [
          emailConfirmationRecipients,
          passengerSelection,
          smsConfirmationRecipients,
        ].every(_isEmpty) && !includeTickets;
  }, [values]);

  const tabsContent = useMemo<Record<typeof tabKeys[number], JSX.Element>>(
    () => ({
      passengersAndTickets: <PassengersAndTickets />,
      fees: <Fees />,
      route: <Route />,
      payerData: <PayerData />,
      eventsAndPayments: <History />,
      customerCommunication: <CustomerCommunication />,
      comments: <Comments />,
    }),
    []
  );

  const actionButtons = useMemo(
    () =>
      [
        {
          icon: <Icon name="mail" />,
          label: <TransButton i18nKey="sendTickets" />,
          hidden: !emailSendAvailable || areAllAdmissionsOnHold,
          onClick: onOpen,
        },
        {
          icon: <Icon width={13} name="download" />,
          label: <TransButton i18nKey="downloadTickets" />,
          disabled: currentBooking?.status === 'ON_HOLD',
          onClick: onTicketDownload,
        },
        {
          icon: <Icon width={13} name="print" />,
          label: <TransButton i18nKey="printTickets" />,
          disabled: true,
          onClick: noop,
        },
      ].filter((btn) => !btn.hidden),
    [
      onOpen,
      onTicketDownload,
      emailSendAvailable,
      currentBooking?.status,
      areAllAdmissionsOnHold,
    ]
  );

  const getTabIcon = useCallback(
    (tab: typeof tabKeys[number]): ReactNode | undefined => {
      let iconName;
      if (
        tab === 'comments' &&
        [...unaccompaniedMinorRequests, ...comments]?.length
      )
        iconName = 'comment';
      if (tab === 'route' && hasNotifications) iconName = 'info-circle';

      return iconName && <Icon name={iconName} className={classes.tabIcon} />;
    },
    [classes.tabIcon, comments, hasNotifications, unaccompaniedMinorRequests]
  );

  useEffect(() => {
    if (IS_IMS_AT) {
      dispatch(getUnaccompaniedMinorRequests(id));
      dispatch(getNotifications(id));
    }
    dispatch(getComments(id));
    dispatch(getHistory(id));
    try {
      (async () => {
        const { bookedTrips } = await dispatch(getBooking(id)).unwrap();
        dispatch(cancelExchangeOperations());
        if (bookedTrips.length === 0) {
          dispatch(getTravelAccount());
        }
      })();
    } catch (e) {
      history.push('/search');
    }

    return () => {
      dispatch(resetCurrentBooking());
    };
  }, [dispatch, history, id]);

  if (!currentBooking) return <Loader size="container" active />;

  return (
    <Loadable loading={loading}>
      <Layout
        sx={{ flex: '0 !important', minHeight: 'unset !important' }}
        header={
          <CardHeader
            sx={{ py: 2, px: 3 }}
            title={
              <Stack direction="row">
                <IconButton
                  onClick={() => history.push('/bookings')}
                  color="inherit"
                >
                  <Icon name="arrow-left" />
                </IconButton>
                <TransTitle
                  i18nKey="booking"
                  values={{ num: currentBooking?.code }}
                />
              </Stack>
            }
            action={
              <>
                {actionButtons.map(
                  ({ icon, label, onClick, disabled }, idx) => (
                    <Button
                      key={idx}
                      color="yellow"
                      startIcon={icon}
                      variant="text"
                      disabled={disabled}
                      onClick={onClick}
                    >
                      <Typography>{label}</Typography>
                    </Button>
                  )
                )}
              </>
            }
          />
        }
      >
        <Box sx={{ p: 3, pt: 2, pb: 2 }}>
          <Stack sx={{ mb: 2 }}>
            <Typography variant="subtitle">
              <TransSubtitle i18nKey="details" />
            </Typography>
          </Stack>
          <BookingsDetailsCard onShowFullRoute={() => setActiveTab('route')} />
        </Box>
      </Layout>
      <Box p={3}>
        <Tabs onChange={(_, tab) => setActiveTab(tab)} value={activeTab}>
          {tabKeys.map((tab) => (
            <TabPanel
              key={tab}
              label={
                <Stack direction="row" alignItems="center">
                  <TransSubtitle i18nKey={tab} />
                  {getTabIcon(tab)}
                </Stack>
              }
              value={tab}
            >
              {tabsContent[tab]}
            </TabPanel>
          ))}
        </Tabs>
      </Box>
      <Modal
        className={classes.sendTicketsModal}
        title={<TransButton i18nKey="sendTickets" />}
        open={open}
        onClose={onClose}
        actionButton={
          <Button
            loading={loading}
            startIcon={<Icon name="mail" />}
            variant="contained"
            disabled={sendTicketsDisabled}
            form={SEND_TICKETS_FORM_ID}
            type="submit"
          >
            <TransButton i18nKey="send" />
          </Button>
        }
      >
        <FormProvider form={ticketFulfillmentForm}>
          <Divider />
          <EmailFulfillment
            formId={SEND_TICKETS_FORM_ID}
            onSubmit={handleSubmit}
          />
          <Divider />
          <SmsFulfillment
            onSubmit={handleSubmit}
            formId={SEND_TICKETS_FORM_ID}
          />
        </FormProvider>
      </Modal>
    </Loadable>
  );
};
