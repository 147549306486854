import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';
// import { Trans } from 'react-i18next';

export const TransAlert = createTrans({
  advanceOrderRequired: (
    <Trans
      i18nKey="alert.advanceOrderRequired"
      defaults="Advance order required"
    />
  ),
  bookingHasExpired: (
    <Trans i18nKey="alert.bookingHasExpired" defaults="Booking has expired" />
  ),
  bookingIsPaid: (
    <Trans
      i18nKey="alert.bookingIsPaid"
      defaults="Payment successful! booking {{id}} is now confirmed."
    />
  ),
  departureDateIsInPast: (
    <Trans
      i18nKey="alert.departureDateIsInPast"
      defaults="Departure date is in the past"
    />
  ),
  fillOneRequired: (
    <Trans
      i18nKey="alert.fillOneRequired"
      defaults="Fill at least one required field"
    />
  ),
  modifySeparately: (
    <Trans
      i18nKey="alert.modifySeparately"
      defaults="Chosen passengers travel on different routes, modification has to be done separately."
    />
  ),
  noPropsAvailable: (
    <Trans i18nKey="alert.noPropAvailable" defaults="No properties available" />
  ),
  selectAtLeastOne: (
    <Trans
      i18nKey="alert.selectAtLeastOne"
      defaults="Select at least one option"
    />
  ),
});
