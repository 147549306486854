import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransButton = createTrans({
  addAddon: <Trans i18nKey="button.addAddon" defaults="Add new add-on" />,
  addons: <Trans i18nKey="button.addons" defaults="Add-ons" />,
  addCode: <Trans i18nKey="button.addCode" defaults="Add another code" />,
  addJourney: <Trans i18nKey="button.addJourney" defaults="Add journey" />,
  addAnotherJourney: (
    <Trans i18nKey="button.addAnotherJourney" defaults="Add another journey" />
  ),
  addJourneyToNewPassenger: (
    <Trans
      i18nKey="button.addJourneyToDifferentPassenger"
      defaults="Add a journey for new passenger(s)."
    />
  ),
  addJourneyToSamePassenger: (
    <Trans
      i18nKey="button.addJourneyToSamePassenger"
      defaults="Add a journey for the current passenger."
    />
  ),
  addNewRecipient: (
    <Trans i18nKey="button.addNewRecipient" defaults="Add new recipient" />
  ),
  addPassengerCard: (
    <Trans i18nKey="button.addPassengerCard" defaults="Add customer card" />
  ),
  addRecipient: (
    <Trans i18nKey="button.addRecipient" defaults="Add new recipient" />
  ),
  addonSelection: (
    <Trans i18nKey="button.addonSelection" defaults="Add-on selection" />
  ),
  addViewComments: (
    <Trans i18nKey="button.addViewComments" defaults="Add/view comments" />
  ),
  advancedSearch: (
    <Trans i18nKey="button.advancedSearch" defaults="Advanced search" />
  ),
  applySeatPreferenceToAll: (
    <Trans
      i18nKey="button.applySeatPreferenceToAll"
      defaults="Apply the same seat preferences to other offers"
    />
  ),
  back: <Trans i18nKey="button.back" defaults="Back" />,
  backToSearch: (
    <Trans i18nKey="button.backToSearch" defaults="Back to search" />
  ),
  cancel: <Trans i18nKey="button.cancel" defaults="Cancel" />,
  cancelBooking: (
    <Trans i18nKey="button.cancelBooking" defaults="Cancel booking" />
  ),
  cancelPayment: (
    <Trans i18nKey="button.cancelPayment" defaults="Cancel payment" />
  ),
  cancelSelected: (
    <Trans i18nKey="button.cancelSelected" defaults="Cancel selected" />
  ),
  clearAll: <Trans i18nKey="button.clearAll" defaults="Clear all" />,
  clearFields: <Trans i18nKey="button.clearFields" defaults="Clear fields" />,
  close: <Trans i18nKey="button.close" defaults="Close" />,
  closeDetails: (
    <Trans i18nKey="button.closeDetails" defaults="Close details" />
  ),
  confirm: <Trans i18nKey="button.confirm" defaults="Confirm" />,
  confirmCancel: (
    <Trans i18nKey="button.confirmCancel" defaults="Confirm cancel" />
  ),
  confirmModification: (
    <Trans
      i18nKey="button.confirmModification"
      defaults="Confirm modification"
    />
  ),

  confirmRefund: (
    <Trans i18nKey="button.confirmRefund" defaults="Confirm refund" />
  ),
  confirmSelection: (
    <Trans i18nKey="button.confirmSelection" defaults="Confirm selection" />
  ),
  confirmTransaction: (
    <Trans i18nKey="button.confirmTransaction" defaults="Confirm transaction" />
  ),
  continue: <Trans i18nKey="button.continue" defaults="Continue" />,
  continueCheckout: (
    <Trans i18nKey="button.continueCheckout" defaults="Continue to checkout" />
  ),
  copyPassengerDataBelow: (
    <Trans
      i18nKey="button.copyPassengerDataBelow"
      defaults="Copy data for passengers below"
    />
  ),
  corporateAgreementCode: (
    <Trans
      i18nKey="button.corporateAgreementCode"
      defaults="Corporate agreement code"
    />
  ),
  delete: <Trans i18nKey="button.delete" defaults="Delete" />,
  detailedAvailability: (
    <Trans
      i18nKey="button.detailedAvailability"
      defaults="Detailed availability"
    />
  ),
  details: <Trans i18nKey="button.details" defaults="Details" />,
  download: <Trans i18nKey="button.download" defaults="Download" />,
  downloadTickets: (
    <Trans i18nKey="button.downloadTickets" defaults="Download tickets" />
  ),
  edit: <Trans i18nKey="button.edit" defaults="Edit" />,
  enterConcessionDetails: (
    <Trans
      i18nKey="button.enterConsessionDetails"
      defaults="Enter concession details"
    />
  ),
  expandAll: <Trans i18nKey="button.expandAll" defaults="Expand all" />,
  fillPassengerDetails: (
    <Trans
      i18nKey="button.fillPassengerDetails"
      defaults="Fill passenger details"
    />
  ),
  makeOnHoldBookingOffer: (
    <Trans
      i18nKey="button.makeOnHoldBookingOffer"
      defaults="Check on-hold booking availability"
    />
  ),
  modifyJourney: (
    <Trans i18nKey="button.modifyJourney" defaults="Modify journey" />
  ),
  modifySeatingAndAddons: (
    <Trans
      i18nKey="button.modifySeatingAndAddons"
      defaults="Modify seating details and add-ons"
    />
  ),
  modifySelected: (
    <Trans i18nKey="button.modifySelected" defaults="Modify selected" />
  ),
  newBooking: <Trans i18nKey="button.newBooking" defaults="New booking" />,
  newTrip: <Trans i18nKey="button.newTrip" defaults="New trip" />,
  next: <Trans i18nKey="button.next" defaults="Next" />,
  nextDepartureOn: (
    <Trans
      i18nKey="button.nextDepartureOn"
      defaults="Next departure on {{date}}"
    />
  ),
  nextArrivalOn: (
    <Trans i18nKey="button.nextArrivalOn" defaults="Next arrival on {{date}}" />
  ),
  none: <Trans i18nKey="button.none" defaults="None" />,
  paySelected: <Trans i18nKey="button.paySelected" defaults="Pay selected" />,
  paymentOverview: (
    <Trans i18nKey="button.paymentOverview" defaults="Overview and payment" />
  ),
  postComment: <Trans i18nKey="button.postComment" defaults="Post comment" />,
  prev: <Trans i18nKey="button.prev" defaults="Previous" />,
  printTickets: (
    <Trans i18nKey="button.printTickets" defaults="Print tickets" />
  ),
  promoCode: <Trans i18nKey="button.promoCode" defaults="Promo code" />,
  putBookingOnHold: (
    <Trans i18nKey="button.putBookingOnHold" defaults="Put booking on hold" />
  ),
  refundDetails: (
    <Trans i18nKey="button.refundDetails" defaults="Refund details" />
  ),
  refundSelected: (
    <Trans i18nKey="button.refundSelected" defaults="Refund selected" />
  ),
  remove: <Trans i18nKey="button.remove" defaults="Remove" />,
  resetFields: <Trans i18nKey="button.resetFields" defaults="Reset fields" />,
  retry: <Trans i18nKey="button.retry" defaults="Retry" />,
  save: <Trans i18nKey="button.save" defaults="Save" />,
  saveChanges: <Trans i18nKey="button.saveChanges" defaults="Save changes" />,
  search: <Trans i18nKey="button.search" defaults="Search" />,
  searchNewJourneys: (
    <Trans i18nKey="button.searchNewJourneys" defaults="Search new journeys" />
  ),
  searchResultsSettings: (
    <Trans
      i18nKey="button.searchResultsSettings"
      defaults="Search results settings"
    />
  ),
  seatPreference: (
    <Trans i18nKey="button.seatPreference" defaults="Seat preference" />
  ),
  seatSelection: (
    <Trans i18nKey="button.seatSelection" defaults="Seat selection" />
  ),
  selectAll: <Trans i18nKey="button.selectAll" defaults="Select all" />,
  selectTrip: <Trans i18nKey="button.selectTrip" defaults="Select trip" />,
  send: <Trans i18nKey="button.send" defaults="Send" />,
  sendTickets: <Trans i18nKey="button.sendTickets" defaults="Send tickets" />,
  sendTicketsToPassengers: (
    <Trans
      i18nKey="button.sendTicketsToPassengers"
      defaults="Send tickets to passengers"
    />
  ),
  sortAsc: <Trans i18nKey="button.sortAsc" defaults="A to Z" />,
  sortBy: <Trans i18nKey="button.sortBy" defaults="Sort by {{sort}}" />,
  sortDesc: <Trans i18nKey="button.sortDesc" defaults="Z to A" />,
  sortNone: <Trans i18nKey="button.sortNone" defaults="Unsorted" />,
  sortPriceAsc: (
    <Trans i18nKey="button.sortPriceAsc" defaults="Price ascending" />
  ),
  sortPriceDesc: (
    <Trans i18nKey="button.sortPriceDesc" defaults="Price descending" />
  ),
  specifyDetails: (
    <Trans i18nKey="button.specifyDetails" defaults="Specify details" />
  ),
  validate: <Trans i18nKey="button.validate" defaults="Validate" />,
  viewAllDayJourneys: (
    <Trans
      i18nKey="button.viewAllDayJourneys"
      defaults="View all journeys for the day ({{num}})"
    />
  ),
  viewBooking: <Trans i18nKey="button.viewBooking" defaults="View booking" />,
  viewDetails: <Trans i18nKey="button.viewDetails" defaults="View details" />,
  viewFullRoute: (
    <Trans i18nKey="button.viewFullRoute" defaults="View full route" />
  ),
  viewStops: <Trans i18nKey="button.viewStops" defaults="View stops" />,
  viewUsage: <Trans i18nKey="button.viewUsage" defaults="View usage" />,
});
